import { Quote, TransportRequestChange } from "@brenger/api-client";
import { Button, Message, Spacer, Strong } from "@brenger/react";
import { formatPrice } from "@brenger/utils";
import cn from "classnames";
import React from "react";
import { useMutation } from "react-query";
import { Modal, ModalProps } from "../../components";
import { useTranslationContext, useTransportContext, useTransportParams } from "../../hooks";
import { coreClient } from "../../utils";

interface Props extends ModalProps {
  oldQuote: Quote | undefined;
  newQuote: Quote | undefined;
  isLoading: boolean;
  errorQuote?: React.ReactNode;
}

type ValidDetailKey = "carrying_help" | "elevator" | "floor_level";

// NOTE: The aim of this util is to compare and convert two quote responses into a
// a TransportRequestChange for the purpose of adding upsells to a TR.
const getDetailChangesFromQuoteStops = (args: {
  from: Quote;
  to: Quote;
  stopType: "pickups" | "deliveries";
}): TransportRequestChange[] => {
  const validDetailKeys: ValidDetailKey[] = ["carrying_help", "elevator", "floor_level"];
  return validDetailKeys
    .map((key): TransportRequestChange => {
      const from = args.from?.[args.stopType][0]?.details?.[key];
      const to = args.to?.[args.stopType][0]?.details?.[key];
      return {
        key: `${args.stopType}[0].details.${key}`,
        from: { value: from ?? "" },
        to: { value: to ?? "" },
      };
    })
    .filter(({ from, to }) => {
      return from.value !== to.value;
    });
};

export const UpdateStopDetailsConfirmModal: React.FC<Props> = ({
  oldQuote,
  newQuote,
  errorQuote,
  isLoading,
  closeHandler,
  isActive,
}) => {
  const { t, i18n } = useTranslationContext();
  const { trId } = useTransportContext();
  const params = useTransportParams();

  const addUpsells = useMutation(coreClient.transportRequestQuotes.create, {
    onSuccess: (trQuote) => {
      window.location.assign(trQuote.payment_redirect_url);
    },
  });

  let priceDiff = 0;

  if (oldQuote && newQuote) {
    priceDiff = newQuote?.price.incl_vat.amount - oldQuote?.price.incl_vat.amount;

    // Do not display negative price to user - just set to zero in this case.
    if (priceDiff < 0) priceDiff = 0;
  }
  return (
    <Modal isActive={isActive} closeHandler={closeHandler}>
      <h3>{t((d) => d.confirm_changes)}</h3>
      <Spacer h={4} />
      <div>
        <span className={cn("mr-1")}>{t((d) => d.cost_of_changes)}</span>
        <Strong>
          {formatPrice({ amount: priceDiff, locale: i18n.locale, currency: oldQuote?.price.vat.currency })}
        </Strong>
      </div>
      {errorQuote && (
        <Message type="error" className={cn("mt-2")}>
          {errorQuote}
        </Message>
      )}
      <Spacer h={4} />
      <div className={cn("grid", "sm:grid-cols-2", "grid-cols-1", "gap-4")}>
        <Button disabled={isLoading} buttonType="primary-outline" onClick={closeHandler}>
          {t((d) => d.no)}
        </Button>
        <Button
          loading={isLoading}
          buttonType="secondary"
          onClick={() => {
            if (oldQuote && newQuote) {
              const pickupChanges = getDetailChangesFromQuoteStops({
                from: oldQuote,
                to: newQuote,
                stopType: "pickups",
              });
              const deliveryChanges = getDetailChangesFromQuoteStops({
                from: oldQuote,
                to: newQuote,
                stopType: "deliveries",
              });

              const allChanges = [...pickupChanges, ...deliveryChanges];

              // When there are upsells to add and price difference is above 0, invoke the mutation!
              if (allChanges.length > 0 && priceDiff > 0) {
                addUpsells.mutate({
                  id: trId || "",
                  data: { transport_request_changes: allChanges },
                });
              } else {
                // Else, when no upsells to add, navigate back to TR overview.
                window.location.assign(params.id);
              }
            }
          }}
        >
          {t((d) => d.agree)}
        </Button>
      </div>
    </Modal>
  );
};
